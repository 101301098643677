import http from '@/libs/http'

const baseUrl = process.env.VUE_APP_MICROSERVICE_API_ENTRADAS_MERCANCIA + '/entradas'
export default class AjustesInventarioService {
  crearAjuste (data) {
    return http.post(`${baseUrl}`, data, {
      headers: {
        loading: true
      }
    }).then(res => res.data).catch(err => err.response.data)
  }

  getAjustes (params) {
    return http.get(`${baseUrl}/paginate`, {
      params,
      headers: {
        loading: true
      }
    })
  }

  getAjuste (Id) {
    return http.get(`${baseUrl}/find/${Id}`, {
      headers: {
        loading: true
      }
    })
  }

  getMotivos () {
    return http.get(`${process.env.VUE_APP_MICROSERVICE_API_ENTRADAS_MERCANCIA}/concepto-entrada/search`, {
      headers: {
        loading: true
      }
    })
  }

  getArticulos (search) {
    return http.get(`${baseUrl}/get-medicamentos/${search}`, {
      headers: {
        loading: true
      }
    })
  }

  getLotes (params) {
    return http.get(`${baseUrl}/get-lotes`, {
      params,
      headers: {
        loading: true
      }
    })
  }
}
