import { defineStore } from 'pinia'
import AjustesInventario from '../services/ajustesInventario'

const _AjustesInventario = new AjustesInventario()

export const useAjustesInventario = defineStore('AjustesInventario', {
  state: () => {
    return {
      Entradas: [],
      Salidas: [],
      NuevaEntrada: {
        WhsCode: null,
        Motivo: null,
        Comments: null,
        Detalle: []
      }
    }
  },
  getters: {
    _Entradas: (state) => state.Entradas,
    _Salidas: (state) => state.Salidas,
    _NuevaEntrada: (state) => state.NuevaEntrada
  },
  actions: {
    crearAjuste () {
      return _AjustesInventario.crearAjuste(this.NuevaEntrada).then(({ data }) => {
        return this.getAjustes()
      }).catch((err) => {
        console.log(err)
      })
    },
    getAjustes (params) {
      return _AjustesInventario.getAjustes(params).then(({ data }) => {
        this.Entradas = data
        return data
      }).catch((err) => {
        console.log(err)
      })
    },
    getSalidas (params) {
      return _AjustesInventario.getAjustes(params).then(({ data }) => {
        this.Salidas = data
        return data
      }).catch((err) => {
        console.log(err)
      })
    },
    getAjuste (Id) {
      return _AjustesInventario.getAjuste(Id).then(({ data }) => {
        this.NuevaEntrada = data
        return data
      }).catch((err) => {
        console.log(err)
      })
    },
    resetData () {
      this.NuevaEntrada = {
        WhsCode: null,
        Motivo: null,
        Comments: null,
        TipoOperacion: 1,
        Detalle: []
      }
    },
    async agregarDetalle (payload) {
      payload.Lotes = await this.getLotes(payload.ItemCode)
      this.NuevaEntrada.Detalle.unshift(payload)
    },
    quitarLinea (index) {
      this.NuevaEntrada.Detalle.splice(index, 1)
    },
    getLotes (ItemCode) {
      return _AjustesInventario.getLotes({
        ItemCode,
        WhsCode: this.NuevaEntrada.WhsCode
      }).then(({ data }) => {
        return data
      }).catch((err) => {
        console.log(err)
      })
    },
    async resetLotes () {
      for (const [index, item] of Object.entries(this.NuevaEntrada.Detalle)) {
        _AjustesInventario.getLotes({
          ItemCode: item.ItemCode,
          WhsCode: this.NuevaEntrada.WhsCode
        }).then(({ data }) => {
          this.NuevaEntrada.Detalle[index].Lotes = data
        })
      }
    }
  }
})
